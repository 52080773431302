import request from "@/config/serve.js";

// 查询
export function getAccountDetailList(params) {
  return request("post", "/developerAccount/getAccountDetailList", params);
}
//开发者充值管理-审核查询
export function getAccountDetail(data) {
  return request("get", "/developerAccount/getAccountDetail", data);
}
//开发者充值管理-充值审核
export function auditRecharge(data) {
  return request("post", "/developerAccount/auditRecharge", data);
}
//获取满足激励政策
export function getRechargeGive(data) {
  return request("get", "/developerAccount/getLaunch", data);
}
//充值审核校验
export function getCheckStatus(data) {
  return request("post", "/developerAccount/checkStatus", data);
}
